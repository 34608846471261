<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :headers="headers"
                :loading="loading"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[ 5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents|CitizenStatements.Document' })">
                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.regdate`]="{ item }">
                    {{ formatDate(item.regdate) }}
                </template>

                <template v-slot:[`item.recdate`]="{ item }">
                    {{ formatDate(item.recdate) }}
                </template>

                <template v-slot:[`item.execdate`]="{ item }">
                    {{ formatDate(item.execdate) }}
                </template>

                <template v-slot:[`item.registrationstate`]="{ item }">
                    <StatusChip :status="getStatus(item)"/>
                </template>

                <template v-slot:[`item.declarant`]="{ item }">
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.declarant">
                            {{item.declarant}}
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.annotation`]="{ item }">
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.annotation">
                            {{item.annotation}}
                        </div>
                    </div>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>

</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapActions, mapGetters } from 'vuex';
import StatusChip from '@/components/StatusChip'

export default {
    components: {        
        StatusChip
    },
    columnIndex:  {
        'regnumber': 3,
        'regdate': 4,
        'correspondent': 5,
        'theme': 6
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: this.$t('Рег._номер'), value: 'regnumber', width: '150px', orderby: 2 },
                { text: this.$t('Рег._дата'), value: 'regdate', width: '120px', orderby: 3 },                    
                { text: this.$t('ИИН/БИН'), value: 'iin_bin', width: '120px', orderby: 13 },
                { text: this.$t('Заявитель'), value: 'declarant', width: '15%', orderby: 6 },
                { text: this.$t('Услуга'), value: 'annotation', orderby: 12 },
                { text: this.$t('Исх._№_заявления'), value: 'uniquenumber', width: '170px', orderby: 22 },                
                { text: this.$t('Статус'), value: 'registrationstate', width: '100px', orderby: 16, sortable: false }
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        ...mapGetters('auth', { userInfo: 'getUserInfo' }),
        options: {
            get: function() {
                return this.$store.getters['inwork/inspectlistC5/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('inwork/inspectlistC5/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['inwork/getCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search
            }
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        getStatus(item) {
            let key = '';

            if (item.registrationstate == 1 && item.executionstatus == 0 && item.paymentstatus != 2 && ![1, 2].includes(item.signaturestatus) &&  !item.hasrecipients && !item.hasresolutions) key = 'acepted'; //принят            
            if (item.registrationstate == 1 && item.executionstatus == 0 && item.paymentstatus != 2 &&  ![1, 2].includes(item.signaturestatus) && item.hasrecipients && !item.hasresolutions) key = 'consideration';
            if (item.paymentstatus == 2 && ![1, 2].includes(item.signaturestatus)) key = 'waitPayment';
            if (item.paymentstatus == 3 && ![1, 2].includes(item.signaturestatus)) key = 'paymentMade';
            if (item.registrationstate == 1 && item.executionstatus == 0 && item.paymentstatus != 2 && ![1, 2].includes(item.signaturestatus) && item.hasresolutions) key = 'onExecution';
            if (item.signaturestatus == 1) key = 'onSignatureOfApplicant';
            if (item.signaturestatus == 2) key = 'onSignatureOfServiceProvider';
            if (item.executionstatus == 1) key = 'executed';
            if (item.executionstatus == 2) key = 'refusal';

            return this.avaibleStatuses[key];
        },
        resetPage() {
            this.$store.commit('inwork/inspectlistC5/SET_TABLE_OPTION_PAGE', 1);
        },
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        async getData(isRefresh = false) {

            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;

            this.setOverlayVisible({ visible: true });
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/inwork/list?collection=MyDocuments.OnInspect.C5&filter=${this.getTableFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;

            this.loading = false;
            this.setOverlayVisible({ visible: false });
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];            
            let OrderBy = this.headers.find(i => i.value == (sortBy.length <= 0 ? 'null' : sortBy[0]))?.orderby ?? -1;

            return JSON.stringify({Category: "Registered", RequestStatus: "Pending", RecipientWorkplaceId: this.userInfo.workplaceId, SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage });
        },
        formatDate (value) {
            if (!value)
                return '';

            return this.$moment(value).format('DD.MM.YYYY');
        }
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    }
}
</script>