<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document' })">

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.regdate`]="{ item }">
                    <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.declarant`]="{ item }">
                    <div class="elipsis-text-wrap">
                        {{ item.declarant }}
                    </div>
                </template>
                
                <template v-slot:[`item.contracttimeend`]="{ item }">
                    <div>{{ item.contracttimeend ? item.contracttimeend : $t('Бессрочный') }}</div>
                </template>

                <template v-slot:footer.prepend>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system";
import { mapGetters } from 'vuex';

export default {
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                //'id' : 1, 
                //'regnumber': 2,								
                //'regdate' : 3,
                //'declarant' : 4,
                //'annotation': 5,	
                //'contracttimeend' : 6

                { text: i18n.t("Дог._№"), value: 'regnumber', width: '150px', orderby: 2 },
                { text: i18n.t("Дог._дата"), value: 'regdate', width: '150px', orderby: 3 },
                { text: i18n.t("С_кем_заключен"), value: 'declarant', width: '15%', orderby: 4 },
                { text: i18n.t("Суть_договора"), value: 'annotation', orderby: 5 },
                { text: i18n.t("Срок_действия"), value: 'contracttimeend', width: '150px', orderby: 6 },
                // { text: i18n.t("Статус"), value: 'status', width: '150px', sortable: false },
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        ...mapGetters('auth', { userInfo: "getUserInfo" }),
        options: {
            get: function() {
                return this.$store.getters['correspondence/contracts/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('correspondence/contracts/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['inwork/getCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                //ControlDocumentState: this.$options.controlDocumentStates[this.status],
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
        extendedTableFilter() {
            return this.$store.getters['correspondence/getExtendedFilter'];
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        }
    },
    methods: {
        itemRowBackground (item) {
            return item.readed === true ? '' : 'font-weight-bold';
        },
        resetPage() {
            this.$store.commit('inwork/signlistC5/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            this.$emit("change_loaddata_status",this.loading);
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            
            let response = await httpAPI({
                
                url: `/api/inwork/list?collection=MyDocuments.OnSign.C5&filter=${this.getTableFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response.data?.payload?.data ?? [];
            this.total = response.data?.payload?.total ?? 0;
            this.loading = false;
            this.$emit("change_loaddata_status",this.loading);
        },
        getTableFilterObject() {
            var { sortBy, sortDesc, page, itemsPerPage, SearchStr, Category, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let OrderBy = this.headers.find(i => i.value == (sortBy.length <= 0 ? 'null' : sortBy[0]))?.orderby ?? -1;
            return JSON.stringify({ ExecutionStatus: 0, SignatureStatus: 2, SignerWorkplaceIds: [this.userInfo.workplaceId], Category, SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage, ...extendedFilterData });
        },
        dateFormat: sys.dateFormat,
        getLocalized(v) {
            try
            {
                let value = JSON.parse(v);
                return i18n.locale == 'ru' ? value['ru-RU'] : value['kk-KZ'];
            }
            catch
            {
                return "";
            }
        }
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
